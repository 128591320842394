@import '../../config/variables.scss';

.Tooltip{
    display:inline-block;
    position: relative;

    .children > *{
        display: flex;
    }

    .description{
        position: absolute;
        // top:20px;
        bottom: 0%;
        left: 50%;
        z-index: 90;
        color: white;
        background-color:rgba(0, 0, 0, 0.712);
        
    /*     -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%); */
        transform: translate(-50%, calc(100% + 8px));
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.6s;
        transition: opacity 0.6s;
        transition-delay: 0s;
        font-size: 12px;
        padding:5px;
        border-radius: 5px;
        white-space: nowrap;
    }

    .visible{
        opacity: 1;
        transition-delay: 0.2s;
    }
}