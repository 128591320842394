@import '../../config/variables.scss';

.ArticleScreen{
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    align-items: center;

    &>.header{
        width:100%;
        height: 450px;
        padding: 80px;
        background-image: url('../../assets/img_balldrive.jpg');
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 180px;

        .title{
            font-size: 40px;
            font-weight: 600;
            letter-spacing: 3px;
            color: white;
            text-shadow: 2px 2px 4px black;
            text-transform: uppercase;
            z-index: 3;
            width:1200px;
            text-align: center;
            // text-decoration: underline;
        }

        .overlay{
            position: absolute;
            width:100%;
            height: 100%;
            top:0;
            left:0;
            right:0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.357);
        }


        .actions{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding:15px 0;
            // border-bottom: 1px solid $red;
            width:1200px;
            position: absolute;
            top:15px;
            right: 30px;

            button{
                padding: 10px;
                margin-left: 10px;
                font-size: 16px;
                border-radius: 3px;
                // border:1px solid black;
                border:none;
                cursor: pointer;
                // color:white;
                font-weight: 600;
                width:110px;
                transition: 0.4s opacity;

                &:hover{
                    opacity: 0.6;
                    transform: scale(0.97);
                }
                // &.save{
                //     background-color: $green;
                //     border-color:$green;
                // }

                // &.delete{
                //     background-color:$red;
                //     border-color:$red;
                // }

                // &.edit{
                //     background-color: $blue;
                //     border-color:$blue;
                // }
            }
        }
    }

    .container{
        z-index: 5;
        width: calc(100vw - 70px);
        display: flex;
        margin-top:-110px;
        justify-content: stretch;
        align-items: center;
        flex-direction: column;
        border:1px solid rgb(228, 228, 228);
        box-shadow: 0px -2px 8px rgba(12, 12, 12, 0.323);
        background-color: white;
        // height: 100%;
        // margin-top: 80px;
        // margin-bottom: 40px;
        border-radius: 3px;
        min-height: calc(100vh - 120px);
        margin-bottom: 40px;

        @media (max-width:1200px){
            width:100vw;
            padding:0 20px;
        }
        
        @media (max-width:900px){
            width:100vw;
            padding:0 0px;
        }

        .editor{
            max-width:1250px;
            width:100%;
            padding:0px 20px;
            margin-bottom: 40px;
            // border:1px solid $darkgray;

            .article-data{
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                align-items: stretch;
                padding:15px 0;
                font-size:20px;
                font-weight: 600;
                color: $blue;

                .line{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin:5px 0;

                }

                .pdf-to-html{
                    display: flex;

                    input{
                        margin-left: 0;
                    }
                    button{
                        margin-left: 10px;
                        width:200px;
                        cursor: pointer;
                    }
                }
                

                input{
                    padding:7px 10px;
                    margin-left: 10px;
                    width:100%;
                    background-color: $smoke;
                    border: 1px solid $smoke;
                    border-radius: 3px;
                    font-size:18px;

                    &:focus{
                        border-color:cornflowerblue;
                    }
                }

                label{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .title, .author{
                    width: calc(100% - 500px);
                }

                .date{
                    width:300px;
                }
            }

            .article-content{
                // height: 100%;
                // width:100%;
                border:1px solid $smoke;
                
                .quill{
                    .ql-toolbar{
                        position: sticky;
                        top:100px;
                        background-color: white;
                        z-index: 60;
                        padding:15px;
                    }
                }


            }
            
        }

        .article{
            max-width:1250px;
            width:100%;
            padding:0px 20px;
            margin:20px 0;
            margin-bottom: 40px;

            .header{
                display: flex;
                justify-content: flex-end;
                align-items: center;

                font-size:16px;
                .author{
                    margin-left:5px;
                }

                color:$darkgray;
                // color:$red;

                // font-weight: 600;
                // background-color: $smoke;
                padding:20px 10px;
                // border-bottom: 1px solid $red;
                // margin-bottom: 20px;
                border-radius: 4px;
            }

            // .ql-editor{
            //     // text-align: justify;
            //     font-size:18px;
            //     img{
            //         width:100%;
            //         object-fit: cover;
            //         margin:0 auto;
            //         text-align: center;
            //     }

            //     iframe{
            //         margin: 0 auto;
            //         // border:2px solid red;
            //         width:100%;
            //         height: 550px;
            //     }

            // }
        }

        .ql-editor{
            min-height: 650px;
            font-size:18px;
            // text-align: justify;
            img{
                // width:100%;
                // object-fit: cover;
                max-width: 100%;
            }

            p.ql-align-justify{
                img{
                    width:100%;
                    object-fit: cover;
                }
            }
            iframe{
                margin: 0 auto;
                // border:2px solid red;
                width:100%;
                height: 550px;
            }
        }

    }  
}       