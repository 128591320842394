

.Slider{
    
    width: 100%;
    height: 800px;
    overflow: hidden;
    position: relative;

    .blocker{
        position: absolute;
        top:0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.453);
        
    }

    .photos{
        top:0;
        left:0;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 3s;
        opacity: 0;

    }

    .opacity{
        opacity: 1;
        transition: 3s;
    }

    .children{
        position: flex;
        width: 100%;
        height: 100%;
        z-index:40;
        // display: flex;
        // justify-content: center;
    }
}