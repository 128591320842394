@import '../../config/variables.scss';

.SignInScreen{
    width:100vw;
    margin-top:85px;
    height: calc(100vh - 199px);
    
    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url('../../assets/img_balldrive.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .container{
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .form{
            background-color: white;

            display: flex;
            flex-direction: column;
            // width:400px;
            height: 500px;
            // border:1px solid rgb(4, 4, 4);
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.618);
            padding:80px 20px;
            border-radius: 3px;

            .header{
                font-size:20px;
                font-weight: 600;
                color: $blue;
                margin-bottom:20px;
                border-bottom: 2px solid $blue;
                padding:10px;
            }

            .content{
                display: flex;
                flex-direction: column;

                input{
                    width:350px;
                    margin-bottom:10px;
                    padding:10px 10px;
                    font-size:16px;
                    outline: none;
                    border:none;
                    background-color: $smoke;
                    border-radius: 3px;
                    color:$darkgray;
                    border:1px solid rgb(228, 228, 228);

                    &:focus{
                        background-color: rgba(47, 165, 255, 0.234);
                        border-color:rgb(0, 123, 255);
                    }
                }   

                .sign-in-button{
                    margin-top:10px;
                    padding:10px;
                    font-size:16px;
                    cursor: pointer;
                    font-weight: 600;
                    color:white;
                    background-color: $green;
                    border:1px solid $green;
                    border-radius: 3px;

                    transition:0.6s;
   

                    &:hover{
                        opacity: 0.7;
                        transform: scale(0.98);
                    }
                }

                .sign-in-error{
                    margin-top:20px;
                    text-align: center;
                    color: $red;
                    font-size:15px;
                    font-weight: 600;
                    border:1px solid $red;
                    padding:10px;
                    border-radius: 3px;
                }
            }
        }
    }
}   