@import '../../config/variables.scss';

.ProductScreen{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    align-items: center;

    input,textarea{
        background-color: inherit;
        font-size:inherit;
        color: inherit;
        font-weight: inherit;
        border: none;
        text-shadow: inherit;
        width:100%;
        // overflow: hidden;
        // text-align: left;
        line-height: inherit;
        // color:$darkgray;
    }

    &>.header{

        width:100%;
        // height: 350px;
        padding: 80px;
        
        // background-size: auto calc(100% - 400px);
        // will-change: transform;
        // background-position: auto;
        // background-color: $smoke;
        // background: rgb(238,238,238);
        // background: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(88,88,88,1) 0%, rgba(168,168,168,1) 100%);
        background-color: $darkgray;
        background: rgb(50,52,55);
        background: linear-gradient(90deg, rgba(50,52,55,1) 0%, rgba(61,61,61,1) 65%, rgba(74,74,74,1) 95%);

        
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
        position: fixed;

        @media (max-width:1500px) {
            padding-left: 20px;
            padding-right: 20px;
            // border:3px solid blue;
          
        }

        @media (max-width:1200px){
            position: static;
            padding: 20px;
        }

    

        // top:0;
        padding-bottom: 180px;
        // background-color: red;
        animation: slideDown 0s ease-out -0.05s;
        animation-fill-mode: forwards;

        &.play-animation{
            animation: slideDown 0.6s ease-out -0.05s;
            animation-fill-mode: forwards;
        }




        @media (min-width:1200px){
            @keyframes slideDown {
                0%{
                    // background-color: red;
                    // height: 100px;
                }
                100%{
                    height: 650px;
                }
            }
        }

        .title{
            font-size: 40px;
            font-weight: 600;
            letter-spacing: 3px;
            color: white;
            text-shadow: 2px 2px 4px black;
            text-transform: uppercase;
            // z-index: 3;
            // text-decoration: underline;
        }

        .overlay{
            position: absolute;
            width:100%;
            height: 100%;
            top:0;
            left:0;
            right:0;
            bottom: 0;
            background-color: $darkgray;
            opacity: 0;
            z-index: 45;

            pointer-events: none;

            @media (max-width:1200px) {
                display: none;
            }
        }

        .header-container{
            // border:1px solid yellow;
            background-color: transparent;
           
       
            padding-right: 10px;
         
            background-origin: content-box;
            // border:none;
            // width: calc(100%);
            // padding: 0;
            // margin:0;
            width: calc(100% - 150px);
            // border:1px solid blue;
            display: flex;
            align-items: center;

            @media (max-width:1500px){
                width:100%;
            }

            @media (max-width: 1200px){
                flex-direction: column;
                padding:0px;
                margin-top:20px;
            }



            // height: 100%;
            // padding-bottom: 450px;

            animation: fadeIn 1.5s ease-in-out;

            .update-actions{
                position: absolute;
                top:0;
                right:0;
                z-index: 80;
                // background-color: white;
                padding:10px;
                border-radius: 3px;

                button,select{
                    font-weight: 600;
                    padding:10px 20px;
                    border:1px solid $blue;
                    border-radius: 3px;
                    margin:0px 5px;
                    cursor: pointer;

            

                }

                button{
                    transition: 0.4s opacity;
                    
                    &:hover{
                        opacity: 0.6;
                        transform: scale(0.97);
                    }
                }

                select{
                    width:220px;
                    padding: 10px;
                }
            }

            .product-photo{
                width:calc(50% - 40px);
                height: 400px;
                overflow: hidden;
                display: flex;
                margin-right: 40px;
                // flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                z-index: 20;
                position: relative;
                // border:1px solid red;

                @media (max-width: 1200px){
                        // display: none !important;
                        width:100% !important;
                        height: 300px !important;
                        // background-color: red;
                        margin:0 !important;
                }

                @media (max-width:500px){
                    height: 200px !important;
                }
                img{
                    height: 100%;
                    object-fit: contain;

                    transition: 1s;
                    // cursor:pointer;

                    &:hover{
                        opacity: 0.7;
                        transform: scale(1.03);
                    }
                }

                input{
                    position: absolute;
                    z-index: 50;
                    bottom:0;
                    cursor: pointer;
                    width:400px;
                    color: white;
                    padding:20px 20px;
                    border-radius: 3px;
                    background-color: rgba(0, 0, 0, 0.446);
                }
                // border:1px solid red;
                // margin-right: 20px;

            }

            .content{
                // background-color: white;
                width:50%;
                z-index: 30;
                color: white;
                text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.282);
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: stretch;
                padding:40px 0; 

                @media (max-width: 1200px){

                    &>*{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        // border:1px solid red;
                        text-align: center;
                    }
                    align-items: center;
                    width:100%;
                    padding: 20px 40px;
                }

                .manufacturer{
                    font-size:30px;
                    font-weight: 600;
                    color: $blue;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.604);
                }

                .product-name{
                    font-size:45px;
                    font-weight: 600;
                    border-bottom: 4px solid $red;
                    padding: 10px 0;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.638);

                    @media (max-width:800px){
                        font-size:35px;
                    }

                }

                .description{
                    margin: 20px 0;
                    font-size:18px;
                    line-height: 1.8rem;

                }

                .actions{
                    margin-top:10px;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    align-items: stretch;

                    

                    @media (max-width:1200px) {
                        justify-content: center;
                        // border:1px solid greenyellow;

                        .download-docs, .accessories{
                            // margin-right: 0 !important; 
                            margin:10px !important;
                        }
                    }

                    .download-docs{
                        margin-bottom: 15px;
                        cursor: pointer;
                        padding:12px 20px;
                        background-color: $green;
                        font-size:17px;
                        font-weight: 600;
                        border:2px solid $green;
                        border-radius: 3px;
                        margin-right: 20px;
                        transition: 0.3s;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width:250px;
                        &:hover{
                            transform: scale(0.97);
                            opacity: 0.9;
                        }

                        .download-icon{
                            // margin-right:10px;
                            font-size:18px;
                        }
                    }

                    .accessories{
                        text-align: center;
                        width:250px;
                        cursor: pointer;
                        padding:12px 20px;
                        background-color: transparent;
                        font-size:17px;
                        font-weight: 600;
                        border: 2px solid $green;
                        border-radius: 3px;
                        margin-right: 20px;
                        transition: 0.3s;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $green;

                        &:hover{
                            transform: scale(0.97);
                            opacity: 0.9;
                        }
                    }

                    
                }
            }


        }

    }

    .product-description{
        z-index: 50;
        width: calc(100vw - 70px);
        display: flex;
        
        justify-content: stretch;
        align-items: stretch;
        border:1px solid rgb(228, 228, 228);
        box-shadow: 0px -2px 8px rgba(12, 12, 12, 0.323);
        background-color: white;
        // height: 100%;
        // margin-top: 80px;
        // margin-bottom: 40px;
        border-radius: 3px;
        // min-height: calc(100vh - 120px);
        margin-bottom: 40px;

        padding:60px;
        padding-top:30px;

        display: flex;
        justify-content: center;

        margin-top:550px;
        // opacity: 0.3;

        @media (max-width:1200px){
            position: static;
            width:100%;
            margin-top: 0px !important;
        }

        &.play-animation{
            margin-top:200px;
            animation: slideDown2 0.6s ease-out -0.05s;
            animation-fill-mode: forwards;
        }

        @keyframes slideDown2 {
            0%{
                margin-top:200px;
            }
            100%{
                margin-top:550px;
            }
        }

        // position: static;
        .container{
            position: static;

            @media (max-width: 1150px){
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .models{
            
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media (max-width: 1150px){
                justify-content: center;

                .content{
                    width:100% !important;
                    // justify-content: center !important;
                }
            }
            // background-color: aliceblue;
            margin-bottom: 10px;
            padding: 10px 0;
            
            .content{
                width:50%;
                display: flex;
                justify-content: flex-start;
                align-items: center;


                @media (max-width:700px){
                    flex-direction: column;

                    .models-list{
                        margin-top: 20px;
                        text-align: center;
                    }
                }

                img{
                    width: 150px;
                    opacity: 0.6;
                }

                .models-list{
                    margin-left: 10px;
                    font-size:18px;
                    width:100%;
                    .models-title{
                        margin-bottom: 5px;
                        font-weight: 600;
                    }

                    textarea{
                        width:100%;
                        // border: 1px solid red;
                        resize:none;
                        height: 50px !important;
                        // padding:5px;
                    }
                }
            }
        }

        .features{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            // border:1px solid red;
            align-items: stretch;

            animation: fadeIn 1.5s ease-in-out;

            .add-feature-container{
                width: 100%;
                display: flex;
                justify-content: center;
            }
            .add-feature{
                display: flex;
                justify-content: space-between;
                align-items: center;

                border:2px solid $green;
                border-radius: 3px;
                padding:10px 20px;
                background-color: $green;
                color:white;
                font-weight: 600;
                font-size:16px;
                cursor: pointer;
                transition: 0.5s;
                // width:200px;

                .add-icon{
                    margin-right: 5px;
                    margin-left: -5px;
                    font-size:20px;
                }

                &:hover{
                    background-color: transparent;
                    color:$green;
                }
            }

            .feature-item{
                width: calc(50% - 50px);
                // min-width: 300px;
        
                padding:20px 10px;
                margin:0px 25px;
                @media (max-width:880px){
                    width:100%;
                    padding:20px 0;
                    margin:0;
                }
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .title{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size:25px;
                    font-weight: 600;
                    // margin-bottom: 20px;
                    padding-bottom: 10px;
                    padding-left: 0px;
                    border-bottom: 4px solid $red;
                    word-wrap: break-word;
                    width:100%;
                    color:$darkgray;
                    // border: 1px red solid;
                    // text-align: center;
                  

                    .group{
                        width: 100%;
                       
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        //   justify-content: center;

                    }

                    .actions{
                        // width:50px;
                    }

                    .box-icon{
                        margin-right: 5px;
                        color: $blue;
                        font-size: 18px;
                    }

                    .add-icon{
                        cursor: pointer;
                        color:$green;

                        &:hover{
                            opacity: 0.7;
                            transform: scale(1.1);
                        }
                    }

                    .delete-icon{
                        cursor: pointer;
                        color:$red;

                        &:hover{
                            opacity: 0.7;
                            transform: scale(1.1);
                        }
                    }
                }

                .feature-description{
                    line-height: 1.8rem;
                    color:$gray;
                    font-size:18px;
                    width:100%;
                    // border:1px solid red;
                    padding: 20px 10px;
                    padding-bottom: 0;
                    .content-editable{
                        // border:1px solid blue;
                    }
                }
            }
        }
    }  
}       