@import '../../config/variables.scss';

.MessageBox{
    position: fixed;
    bottom:0;
    left:0;
    right: 0;

    // z-index: 90;
    
    width:100vw;
    padding: 10px;
    min-height: 35px;
    text-align: center;
    background-color: rgb(137, 208, 248);
    color: $blue;
    border-top:2px solid $blue;
    z-index: 100;
    animation: notify 5s linear forwards;

    @keyframes notify{
        0%{
            opacity: 0;
        }
        20%{
            opacity: 0.9;
        }
        80%{
            opacity: 0.9;
        }
        100%{
            opacity: 0;
        }
    }
}

