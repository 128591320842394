@import '../../config/variables.scss';

.NewsScreen {
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    align-items: center;

    &>.header {
        width: 100%;
        padding: 80px;
        background-image: url('../../assets/img_balldrive.jpg');
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 180px;

        .title {
            font-size: 40px;
            font-weight: 600;
            letter-spacing: 3px;
            color: white;
            text-shadow: 2px 2px 4px black;
            text-transform: uppercase;
            z-index: 3;
            // text-decoration: underline;
        }

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.2);
        }


    }

    .container {
        z-index: 5;
        width: calc(100vw - 70px);
        display: flex;
        margin-top: -110px;
        justify-content: stretch;
        align-items: stretch;
        border: 1px solid rgb(228, 228, 228);
        box-shadow: 0px -2px 8px rgba(12, 12, 12, 0.323);
        background-color: white;
        // height: 100%;
        // margin-top: 80px;
        // margin-bottom: 40px;
        border-radius: 3px;
        min-height: calc(100vh - 120px);
        margin-bottom: 40px;

        @media (max-width:800px) {
            width: 100vw;
        }

        .margin-container {
            // border:1px solid blue;
            // width:1400px;
            width: 100%;
            max-width: 1400px;

            margin: 20px auto;


            .pagination {
                // position: absolute;
                // margin-bottom: 0;
                margin: 0 auto;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px 20px;
                width: calc(100% - 40px);
                // box-shadow: 1px 1px 3px black;
                // border-top: 1px solid $smoke;

                .pagination-page {
                    padding: 10px 15px;

                    // border:1px solid $re;
                    border-radius: 3px;
                    margin: 0 5px;
                    color: $gray;
                    font-weight: 600;
                    cursor: pointer;

                    &:hover {
                        // text-decoration: underline;
                        background-color: rgb(204, 239, 255);
                    }

                    &.selected {
                        background-color: $blue;
                        color: white;
                    }
                }
            }

            .articles {
                padding: 20px 0;

                &>.content {
                    width: 100%;
                    max-width: 950px;
                    margin: 0 auto;
                    padding: 0 40px;
                    // border:1px solid red;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    // justify-content: stretch;
                    gap: 40px;

                    .add-article {
                        padding: 10px 20px;
                        width: 160px;
                        margin: 0 auto;
                        text-align: center;
                        background-color: $green;
                        color: white;
                        font-weight: 600;
                        border: 1px solid $green;
                        border-radius: 3px;

                        cursor: pointer;

                        transition: 0.4s;

                        &:hover {
                            background-color: transparent;
                            color: $green;
                        }
                    }

                    .article {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.433);
                        padding: 30px;
                        border-radius: 3px;
                        width: 100%;
                        max-width: 850px;
                        // min-height: 250px;
                        // height:max-content;
                        cursor: pointer;
                        // border: 1px solid red;;

                        transition: 0.5s;
                        overflow: hidden;

                        @media (max-width:700px) {
                            flex-direction: column-reverse;
                            align-items: center;
                            justify-content: center;


                        }

                        &:hover {
                            transform: scale(1.02);

                            .title {
                                text-decoration: underline;
                            }
                        }

                        .info {
                            width: calc(100% - 230px);

                            @media(max-width:700px) {
                                width: 100%;
                                text-align: center;
                            }

                            // border:1px solid blue;
                            // width:200px;

                            .line {
                                display: flex;
                                // justify-content: space-between;
                                align-items: center;


                                @media (max-width:700px) {
                                    margin-top: 20px;
                                    justify-content: center;
                                }
                            }

                            .author {
                                color: $gray;
                                margin-left: 5px;

                            }

                            .date {
                                color: $gray;
                                font-size: 16px;
                            }

                            .title {
                                margin: 15px 0;
                                font-size: 30px;
                                font-weight: 700;
                                color: $darkgray;
                                color: $blue;
                            }

                            .content {
                                text-align: justify;

                                @media(max-width:700px) {
                                    text-align: center;
                                }

                                color:$darkgray;
                                line-height: 1.4rem;
                                font-size:17px;

                                // white-space: pre-wrap;
                                // word-break: break-wo;
                                display: -webkit-box;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                width:100%;
                            }

                        }

                        .photo {
                            width: 200px;

                            @media(max-width:700px) {
                                // text-align: center;
                                width: 100%;
                                max-height: 300px;
                            }

                            // height: 100%;
                            min-height: 180px;
                            // height: max-content;
                            overflow: hidden;
                            display: flex;
                            align-items: center;
                            // border:1px solid red;
                            border-radius: 3px;

                            // padding: 3px;
                            img {
                                box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.638);
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                                border-radius: 3px;
                                // border:1px solid red;
                                //    display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}