

.Blocker{
    position: fixed;
    width:100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.705);
    top:0;
    bottom: 0;
    right:0;
    left:0;
    z-index:80;
    display: flex;
    justify-content: center;
    align-items: center;
}