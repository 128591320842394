@import '../../config/variables.scss';

.ProductsScreen{
    margin-top: 100px;
    @media (max-height:500px){
        margin-top: 20vh;
    }
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    align-items: center;


    &>.header{
        width:100%;
        height: 350px;
        padding: 80px;
        background-image: url('../../assets/img_balldrive.jpg');
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 180px;

        .title{
            font-size: 40px;
            font-weight: 600;
            letter-spacing: 3px;
            color: white;
            text-shadow: 2px 2px 4px black;
            text-transform: uppercase;
            z-index: 3;
            // text-decoration: underline;
        }

        .overlay{
            position: absolute;
            width:100%;
            height: 100%;
            top:0;
            left:0;
            right:0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.2);
        }


    }

    .container{
        z-index: 5;
        width: calc(100vw - 70px);
        display: flex;
        margin-top:-110px;
        // @media (max-height:500px){
        //     margin-top: calc(-20vh) !important;
        // }
        justify-content: stretch;
        align-items: stretch;
        border:1px solid rgb(228, 228, 228);
        box-shadow: 0px -2px 8px rgba(12, 12, 12, 0.323);
        background-color: white;
        position: relative;
        // height: 100%;
        // margin-top: 80px;
        // margin-bottom: 40px;
        border-radius: 3px;
        min-height: calc(100vh - 120px);
        margin-bottom: 40px;

        @media (max-width: 800px){
            flex-direction: column;
        }

        @media (max-width:700px){
            width: 100%;
        }

        .categories{
            // background-color: $smoke;
            width:400px;
            @media (max-width: 1200px){
                width:350px;
            }
            @media (max-width: 1000px){
                width:300px;
            }
            @media (max-width:800px){
                width: 100%;
                // background-color: $smoke;
            }
            box-shadow: 1px 0px 2px rgb(162, 162, 162);
            // height: 100%;
            // background-color: rgba(255, 255, 255, 0.296);
            // background-color: white;
            z-index: 10;
            // background-color: $gray;

            &>.header{
                padding:25px 35px ;
                // margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .updown-icon{
                    // border:1px solid blue;
                    font-size:20px;
                    margin-left: 5px;
                    margin-bottom: -2px;
                    display: none;
                }
                @media (max-width:800px){
                    margin:0 20px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.152);
                    justify-content: center;

                    .updown-icon{
                        display: inline-block;
                    }
                }
                .title{
                    font-size:25px;
                    font-weight: 600;
                    color: $darkgray;
                    color: $red;
                }

                .actions{
                    .add-icon{
                        font-size:25px;
                        color: $green;
                        cursor: pointer;

                        &:hover{
                            opacity: 0.5;
                            transform: scale(1.2);
                        }
                    }
                }

            }
            .content{
                position: sticky;
                top:90px;
                @media (max-height:500px){
                    // top: 20vh;
                }
                padding: 0 20px;
                
                @media (max-width:800px){
                    border-top:1px solid rgba(0, 0, 0, 0.152);
                    border-bottom:1px solid rgba(0, 0, 0, 0.152);
                    background-color: $smoke;
                    padding:20px 40px;

                    &.hidden{
                        display: none;
                    }
                }

       
                .category{
                    padding:0 5px;

                    &.selected{
                        &>.header-category{
                            .title{
                                text-decoration: underline;
                                color:$red;
                            
                            }
                        }
                    }

                    &>.header-category{
                        padding: 10px;
                        border-bottom: 2px solid $red;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        @media (max-width:800px){
                            justify-content: center;
                        }
                        .title{
                            font-size:20px;
                            // color:$blue;
                            color: $darkgray;
                            @media (max-width:800px){
                                text-align: center;
                            }
                       
                            // font-weight: 600;

                            &:hover{
                                color:$red;
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }

                        .actions{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            color:$darkgray;

                            .add-icon{
                                font-size:22px;
                                cursor: pointer;
                                color:$green;
                                &:hover{
                                    opacity: 0.5;
                                    transform: scale(1.2);
                                }
                            }

                            .edit-icon{
                                font-size:20px;
                                cursor: pointer;
                                color:$blue;
                                &:hover{
                                    opacity: 0.5;
                                    transform: scale(1.2);
                                }
                            }

                            .delete-icon{
                                font-size:20px;
                                cursor: pointer;
                                color:$red;
                                &:hover{
                                    opacity: 0.5;
                                    transform: scale(1.2);
                                }
                            }
                        }
                    }

                    .subcategories{
                        padding: 10px 0;

                      
                        .subcategory{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            @media (max-width:800px){
                                justify-content: center;
                            }

                            padding: 5px 30px;
                            
                            cursor: pointer;
                            border-radius: 3px;

                            &:hover, &.selected{
                           
                                .title{
                                    // letter-spacing: 1px;
                                    padding-left: 5px;
                                    color:$red;
                                    text-decoration: underline;
                                   
                                }
                            }

                            .title{
                                color: $gray;
                                font-size: 17px;
                                // font-weight: 600;
                                transition: 0.4s;
                                @media (max-width:800px){
                                    text-align: center;
                                }
                            }
                            
                            .actions{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                color:$darkgray;

                                .edit-icon{
                                    font-size:18px;
                                    cursor: pointer;
                                    color:$blue;
                                    &:hover{
                                        opacity: 0.5;
                                        transform: scale(1.2);
                                    }
                                }
    
                                .delete-icon{
                                    font-size:18px;
                                    cursor: pointer;
                                    color: $red;
                                    &:hover{
                                        opacity: 0.5;
                                        transform: scale(1.2);
                                    }
                                }
                            }

                        }
                    }
                }
            }   
        }
        
        .products{
            width: calc(100% - 400px);
            @media (max-width: 1200px){
                width: calc(100% - 350px);
            }
            @media (max-width: 1000px){
                width:calc(100% - 300px);
            }
            @media (max-width:800px){
                width: 100%;
            }
            background-color: white;
           
            // border:1px solid black;
            // padding: 20px;
            // background-color: $smoke;
            // background-color:$darkgray;

            .header{
                position: sticky;
                top:95px;
                @media (max-height:500px){
                    top: 20vh;
                }
                padding:20px 15px;
                background-color: white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                gap: 20px;
                border-bottom:2px solid rgb(220, 220, 220);
                margin:0 20px;
                // padding-bottom: 18px;
                z-index: 10;
                // background-color: red;
                // justify-content: center;
                // border:1px solid red;
                @media (max-width:800px){
                    flex-direction: column;
                    padding-left:5px;
                    padding-right: 5px;
                }

                .title{
                    font-size: 20px;
                    font-weight: 600;
                    // border:1px solid red;

                    span{
                        color:$darkgray;
                    }

                    .category-link{
                        cursor: pointer;

                        &:hover{
                            text-decoration: underline;
                            color:$red;
                        }
                    }

                    .spacer{
                        color:$red;
                        font-weight: 1000;
                        margin:0 7px;
                    }
                }

                .search{
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    // border:1px solid red;
                    // width:100%;

                    @media (max-width:800px){
                        justify-content: center;
                        width:100%;
                    }

                    input{
                        padding: 7px 10px;
                        outline: none;
                        border: 1px solid $smoke;
                        background-color: $smoke;
                        font-size: 15px;
                        border-bottom-left-radius: 4px;
                        border-top-left-radius: 4px;
                        border-right: none;
                        width:250px;

                        color:$darkgray;
                        transition: 0.8s;

                        @media (min-width:1000px){
                            &:focus{
                                width:300px;
                            }
                        }


                        @media (max-width:800px){
                            width: 100%;
                            max-width: 400px;
                        }
                    }

                    .search-button{
                        font-size: 15px;
                        padding:7px 15px;
                        background-color: $blue;
                        border: 1px solid $blue;
                        color:white;
                        font-weight: 600;
                        border-bottom-right-radius: 4px;
                        border-top-right-radius: 4px;
                        cursor: pointer;
                        display: flex;
                        // justify-content: space-between;
                        align-items: center;
                        transition: 0.4s; 

                        &:hover{
                            color: $blue;
                            border-color: $blue;
                            background-color: white;
                        }

                        .search-icon{
                            font-weight: 900;
                            margin-left: 5px;
                            font-size: 17px;
                        }
                    } 
                }
            }

            .content{
                width: 100%;
                position: relative;
                // min-height: calc(100% - 175px);
                // background-color: yellow;
                display: flex;
                justify-content: center;
                align-items:flex-start;
                flex-wrap: wrap;
                padding: 0px 10px;
                // padding-bottom: 20px;

                .using-filters{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                    width:100%;
                    padding:20px 30px;
                    text-align: right;
                    font-weight: 600;
                    font-size:14px;
                    color: $darkgray;

                    background-color: $smoke;

                    button{
                        padding: 10px;
                        background-color: $blue;
                        color: white;
                        border:1px solid $blue;
                        border-radius: 3px;
                        font-weight: 600;
                        margin-left: 10px;
                        cursor: pointer;

                        &:hover{
                            background-color: transparent;
                            color: $blue;
                        }
                    }
                }

                .no-products{
                    padding: 60px 0;
                    margin:0 10px;
                    width:100%;
                    text-align: center;
                    font-size:16px;
                    font-weight: 500;
                    color:$darkgray;
                    border-bottom: 2px solid rgb(220, 220, 220);;
                }

                .add-product-container{
                    // background-color: $smoke;
                    width:100%;
                    padding: 20px;
                    display: flex;
                    justify-content: center;
                    margin-top:10px;

                    .add-product{
                        background-color: $green;
                        color:white;
                        font-size: 18px;
                        font-weight: 600;
                        padding:10px 20px;
                        width: 180px;
                        text-align: center;
                        border:2px solid $green;
                        transition: 0.5s;

                        &:hover{
                            color:$green;
                            background-color:transparent;
                        }
                    }
                }

                .product{
                    width:calc(50% - 40px);
                    min-width: 400px;

                    @media (min-width:1500px){
                        height: 200px;
                    }
               
                    @media (max-width:1500px){
                        width: calc(100% - 80px);
                        // background-color: red;
                    }

                    @media (max-width:1000px){
                        width: calc(100%);
                        // background-color: red;
                    }
                    display: flex;
                    justify-content: space-between;
                    align-items: stretch;
                    flex-wrap: wrap;
                    @media (max-width:600px){
                        flex-direction: column;
                        align-items: center;
                        min-width: 0;
                        gap:10px;
                        & > * {
                            // border:1px solid red;
                            text-align: center;
                        }
                    }
                    // background-color: rgba(255, 255, 255, 0.097);
                    
                    padding:20px;
                    cursor: pointer;
                    border-radius: 3px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    //opt1
                    // background-color: $smoke;
                    // border:1px solid rgb(225, 225, 225);
                    // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.145);
                    // border-radius:3px;

                    //opt2
                    border-bottom:2px solid $smoke;
                    margin:0 15px;
                    margin-top:10px;
                    //opt3 - brak
                    
                    .photo{
                        transition: 0.5s;
                        width:200px;
                        overflow: hidden;
                        // border:1px solid red;
                        max-height: 160px !important;
                        img{
                         
                            transition: 0.5s;
                            width:100%;
                            height: 100%;
                            object-fit: contain;
                            // overflow: hidden;
                        }
                    }

                    .product-info{
                        width: calc(100% - 200px);
                        // min-width: 150px;
                        // border:2px solid red;
                        padding: 10px 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: stretch;

                        @media (max-width:600px){
                            width:100%;
                            padding:10px 0;
                        }

                        .manufacturer{
                            color: $blue;
                            font-weight: 600;
                        }

                        .name{
                            font-weight: 600;
                            margin:10px 0;
                        }

                        .description{
                  
                            color:$gray;
                            max-height: 70px;
                            // width:200px;
                            // min-height: 90px;
                            // white-space: nowrap;

                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            // white-space:nowrap
                        }
                    }

                    transition: 0.4s;

                    &:hover{
                        background-color: $smoke;

                        .name{
                            text-decoration: underline;
                        }

                        .photo{
                            opacity: 0.8;
                            img{
                                transform: scale(1.08);
                            }
                        }
                    }


                }

 
            }

            .pagination{
                // position: absolute;
                // margin-bottom: 0;
                margin:0 auto;
                bottom:0;
                display: flex;
                justify-content: center;
                align-items: center;
                padding:20px 20px;
                width:calc(100% - 40px);
                // box-shadow: 1px 1px 3px black;
                // border-top: 1px solid $smoke;

                .pagination-page{
                    padding: 10px 15px;

                    // border:1px solid $re;
                    border-radius: 3px;
                    margin:0 5px;
                    color: $gray;
                    font-weight: 600;
                    cursor: pointer;

                    &:hover{
                        // text-decoration: underline;
                        background-color: rgb(204, 239, 255);
                    }

                    &.selected{
                        background-color: $blue;
                        color:white;
                    }
                }
            }
        }
    }  

    .category-editor{
        width:700px;
        // height: 500px;
        background-color: white;
        padding:20px;
        border-radius: 3px;

        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        position: relative;

        .close-icon{
            position: absolute;
            top:20px;
            right:20px;
            font-size:27px;
            font-weight: 600;
            color:$blue;
            cursor: pointer;

            &:hover{
                color: $purple;
            }
        }

        .title{
            font-size: 20px;
            font-weight: 600;
            color: $blue;
            padding: 10px 0;
            border-bottom: 2px solid $red;
        }

        .content{
            margin:20px 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            // border:1px solid blue;

            .line{
                padding: 5px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .fieldname{
                    font-size: 17px;
                    font-weight: 600;
                    // background-color: red;
                    width:200px;
                    color:$darkgray;
                }

                input{
                    background-color: $smoke;
                    border: none;
                    padding: 7px 10px;
                    border-radius: 3px;
                    width: calc(100% - 200px);
                    // font-weight: 600;
                    color:black;
                    font-size:16px;
                }
            }

            .category-image{
                // border:1px solid red;
                padding: 10px 5px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                overflow: hidden;

                img{
                    height: 200px;
                    border: 1px solid $smoke;
                    border-radius: 4px;
                    overflow: hidden;
                    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
                    object-fit: cover;
                }
            }
        }

        .actions{
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .save-button{
                padding: 10px 20px;
                background-color: $green;
                font-weight: 600;
                color: white;
                border-radius: 3px;
                cursor: pointer;
                border:1px solid $green;

                transition: 0.4s;

                &:hover{
                    color: $green;
                    background-color: transparent;
                    border-color:$green;
                }
            }
        }
    }
}       