@import '../../config/variables.scss';

.SettingsScreen{
    margin-top: 100px;
    width: 100vw;
    height: calc(100vh - 210px);

    .container{
        margin: 0 auto;
        // border:1px solid red;
        padding: 20px 10px;

        .title{
            font-weight: 600;
            font-size: 25px;
            text-align: center;
            border-bottom: 2px solid $blue;
            padding:20px;
            padding-bottom: 30px;
            margin-bottom: 30px;
            color: $blue;
            letter-spacing: 1px;
        }

        .content{
            display: flex;
            justify-content: space-between;
            .box{
                width: calc(50% - 40px);
                padding:20px;
                // background-color: smoke;
                margin:10px;
                border-radius: 4px;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.337);

                .box-title{
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    color: $blue;
                    // text-align: center;

                }

                .box-content{
                    height: calc(100% - 30px);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: stretch;

                    &>*{
                        width:100%;
                        // border:1px solid red;
                    }
                }

                &.change-password{
                    label{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding:10px 0;
                        font-size:16px;

                        input{
                            width: calc(100% - 200px);
                            padding:5px;
                            font-size:16px;
                            background-color: $smoke;
                            border:1px solid $darkgray;
                            border-radius: 3px;

                            &:focus{
                                background-color: rgb(108, 208, 255);
                                border-color: rgb(0, 102, 255);
                            }
                        }
                    }

                    button{
                        margin-top:10px;
                        padding:10px 5px;
                        font-size:16px;
                        cursor: pointer;
                        border:1px solid $green;
                        background-color: $green;
                        font-weight: 600;
                        color:white;
                        border-radius: 3px;
                        transition: 0.4s;

                        &:hover{
                            color:$green;
                            background-color: transparent;
                        }
                    }
                }

                &.backup-now{
                    button{
                        margin-top:10px;
                        padding:10px 5px;
                        font-size:16px;
                        cursor: pointer;
                        border:1px solid $green;
                        background-color: $green;
                        font-weight: 600;
                        color:white;
                        border-radius: 3px;
                        transition: 0.4s;

                        &:hover{
                            color:$green;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}   