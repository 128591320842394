@import '../../config/variables.scss';

.Header{

    position: fixed;
    top:0;
    width: 100vw;
    height: 100px;

    z-index: 90;
    background-color: #ffffff00;
    // background-color: rgba(0, 0, 0, 0);
    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 2px 2px 15px #00000028;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.021);
    border-bottom: 4px solid $red;
    border-top: 4px solid $blue;
    background-color: white;
    // background-color: rgba(255, 255, 255, 0.7);
    // transition: 0.2s;
    @media (max-height:500px){
        height: 20vh;
    }

    &.header-style-2{
        background-color: rgba(255, 255, 255, 0);
        border:none;

        color:white;
        box-shadow: none;

        nav{
            .link{
                color: $smoke !important;

                &:hover{
                    background-color: rgba(140, 140, 140, 0.693) !important;
                }

                &.selected{
                    text-decoration: underline white !important;
                }
            }
        }
    }

    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        transform: none !important;
        .logo{
            height: 50px;
            object-fit: contain;
            @media (max-width:800px){
                max-width:55vw;
            }
            @media (max-height:500px){
                max-width:45vh;
            }
        }
    
        .hamburger-icon{
            display: none;
            font-size:40px;
            border-radius: 4px;
            color:$darkgray;

            &:hover{
                background-color: $smoke;
                padding:5px;
                transform: scale(1.2);
            }

            @media (max-width:1100px){
                display: block;
            }
        }

        nav{
            display: flex;
    
            .link{
                // @media (max-width:1100px){
                //     display: none;
                // }
                margin:0 1px;
                padding:15px 10px;
                cursor: pointer;
                user-select: none;
                // font-weight: 600;

                text-transform: uppercase;
                font-size: 17px;

                color:rgb(28, 28, 28);
                // color:white;
                // font-family: Arial, Helvetica, sans-serif;
                // border-bottom:3px solid rgba(165, 28, 28, 0.741);
                // border-bottom-left-radius: 0;
                // border-bottom-right-radius: 0;

                border-radius: 4px;

                transition: 0.4s;

                &:hover{
                    // border-bottom-color: black;
                    // text-decoration: underline;
                    background-color: rgb(245, 245, 245);
                }

                &.selected{
                    text-decoration: underline rgb(141, 141, 141);
                    text-underline-offset: 2px;
                    text-decoration-thickness: 2px;
                }
            }

            @media (max-width:1100px){
                position: absolute;
                width:100vw;
                height: calc(100vh - 95px);
                top:95px;
                @media (max-height:500px){
                    top: calc(20vh - 5px);
                }
                left: 0;
                background-color: rgba(0, 0, 0, 0.912);

                display: flex;
                flex-direction: column;;
                align-items: center;
                justify-content: center;
                // padding-top:60px;
                &.hidden{
                    display: none;
                }

                .link{

                    color:white;
                    font-size: 3vh;

                    &:hover{
                        color:black;
                    }
                }
            }
        }
    
    }

    .user{
        position: absolute;
        // border:1px solid black;
        display: flex;
        justify-content: space-between;
        align-items: center;

        right:30px;

        .photo{
            width: 60px;
            overflow: hidden;
            // background-color: red;

            img{
                width:100%;
                object-fit: cover;
            }
        }

        .user-data{
            padding:5px 10px;
            padding-left: 0;

            .login{
                font-weight: 600;
                font-size:17px
        
            }

            .bottom-container{
                font-size: 13px;
                font-weight: 600;
                color: $blue;
                text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.419);

                display: flex;
                justify-content: space-between;
                align-items: center;

                .account-type{
                    margin-right: 5px;
                }

                .settings-icon, .logout-icon{
                    font-size: 17px;
                    cursor: pointer;
                    
                    &:hover{
                        color: $red;
                        transform: scale(1.1);
                    }
                }


            }

        }
    }

}