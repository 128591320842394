
// $violet: #7a1bb9;
// $violet: #0e517e;

// $violet: rgb(22, 72, 136);

// $purple: #7a1bb9;

$gray:#999999;
$darkgray: #323437;
$green:#2eb44f;
$smoke: #EFEFEF;;
$red: #e42828;
$purple: purple;
$blue: #3b468f;
$bgRed: rgb(253, 175, 175);
$warning:rgb(223, 185, 15);
// $warning: rgb(226, 167, 5);
// $violet: #572ea5;rgb(234, 234, 238)