@import '../../config/variables.scss';


.MainScreen {
    width: 100vw;

    // background-color: red;
    // border:1px solid red;
    // height: calc(100vh - 75px);
    .section-introduction {
        width: 100vw;
        height: calc(100vh - 20px);
        background-image: url('../../assets/Tsudakoma_Ball_Drive_5osi.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // background-attachment: fixed;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.556);
        }

        .start-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            text-shadow: 3px 3px #000000cb;

            z-index: 10;

            .title {
                font-size: 60px;
                font-weight: 900;

                @media (max-width:700px) {
                    font-size: 8vw;
                }


                // letter-spacing: 1px;
            }

            .subtitle {
                margin-top: 20px;
                font-size: 20px;

                @media (max-width:700px) {
                    font-size: 3vw;
                    text-align: center;
                }

            }
        }

        .show-more {
            position: absolute;
            bottom: 70px;
            color: white;
            text-shadow: 0px 0px 5px rgb(0, 0, 0);
            letter-spacing: 1px;
            font-size: 16px;
            // font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            // border: 1px solid red;
            animation: bounce 3s infinite;
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
            width: 145px;
            border-bottom: 1px solid transparent;

            &:hover {
                // text-decoration: underline;
                border-bottom: 1px solid white;
            }

            span {
                margin-left: 7px;
                ;
            }

            .show-more-icon {
                font-size: 25px;
                padding: 0;
                margin-left: -5px;
            }
        }

        @keyframes bounce {
            0% {
                transform: translateY(0px);
            }

            40% {
                transform: translateY(0px);
            }

            45% {
                transform: translateY(10px);
            }

            50% {
                transform: translateY(0px);
            }

            55% {
                transform: translateY(10px);
            }

            60% {
                transform: translateY(0px);
            }

            65% {
                transform: translateY(0px);
            }

        }
    }

    .overview {
        width: 100vw;

        // height: 980px;

        display: flex;
        justify-content: center;
        align-items: stretch;
        // display: none;
        // padding:20px;
        // background-color: $smoke;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;


            .subsection {

                .header {
                    width: calc(100% - 40px);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 0 20px;

                    .title {
                        text-align: center;
                        padding: 0 20px;
                        padding-bottom: 10px;
                        font-size: 30px;
                        // text-transform: uppercase;
                        // color:$blue;
                        color: $darkgray;
                        // width:400px;
                        letter-spacing: 1px;
                        border-bottom: 3px solid $red;
                        font-weight: 600;
                        margin-bottom: 30px;
                    }

                    .subtitle {
                        margin-bottom: 30px;
                        color: $gray;
                        text-align: center;
                        font-size: 18px;
                        padding: 0 20px;
                    }
                }

                .content {
                    display: flex;
                    justify-content: center;
                    align-items: stretch;
                    // border:1px solid red;
                    flex-wrap: wrap;

                    .item {
                        // width:calc(33% - 80px);
                        width: 385px;
                        margin: 0 40px;
                        display: flex;
                        flex-direction: column;
                        justify-self: stretch;
                        margin-bottom: 40px;
                        // border:1px solid $gray;
                        // border-radius: 3px;
                        // padding:10px 5px;

                        // align-items: stretch;
                        .photo-icon {
                            height: 70px !important;
                        }

                        .photo {
                            width: 100%;
                            height: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            // border:1px solid red;
                            // background-color: $smoke;
                            // padding:20px;
                            // border-radius: 4px;
                            // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.166) inset;

                            .icon {
                                font-size: 65px;

                                &.chat-icon {
                                    color: #E91E63;
                                }

                                &.tick-icon {
                                    color: #00BCD4;
                                }

                                &.support-icon {
                                    color: #4CAF50;
                                }
                            }

                            img {
                                // width: 100%;
                                height: 100%;
                            }


                        }

                        .title {
                            text-align: center;
                            font-size: 18px;
                            font-weight: 600;
                            margin: 20px 0;
                            letter-spacing: 1px;
                        }

                        .description {
                            line-height: 1.5rem;
                            color: $gray;
                            text-align: justify;

                            @media (max-width:800px) {
                                text-align: center;
                            }
                        }
                    }
                }

                &.products {
                    margin-top: 40px;

                    .title {
                        margin-bottom: 40px;
                    }

                    .category {
                        padding: 10px;
                        text-align: center;
                        margin-bottom: 15px;
                        border: 1px solid rgba(160, 160, 160, 0.426);
                        cursor: pointer;
                        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.166);
                        border-radius: 3px;
                        color: #888888;

                        transition: 0.5s;

                        b {
                            color: $darkgray;
                        }

                        &:hover {
                            transform: scale(1.04);
                            text-decoration: underline;
                        }
                    }

                    .photo {
                        // transition: 1s !important;
                        // cursor: pointer;
                        background-color: $smoke;
                        padding: 15px;
                        border-radius: 4px;
                        height: 200px !important;
                        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.166) inset;

                        img {
                            transition: 0.6s;
                            width: 100%;
                            object-fit: contain;
                        }

                        &:hover {
                            img {
                                transform: scale(1.1) !important;
                                opacity: 0.6;
                            }
                        }
                    }
                }
            }
        }
    }

    .company-info {
        margin-top: 40px;
        width: 100vw;
        // height: 300px;
        background-image: url('../../assets/img_balldrive.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;

        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 20px 40px;

        @media (max-width:1150px) {
            padding: 0px
        }


        .overlay {
            top: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.665);
        }

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 30;
            // flex-wrap: wrap;

            @media (max-width:1150px) {
                flex-direction: column;

                .content,
                .video-section {
                    width: 80% !important;
                    text-align: center !important;

                    .description {
                        margin-top: 20px;
                        text-align: center !important;
                        padding: 0 !important;
                    }
                }
            }


            .content {
                padding: 20px 10px;
                width: calc(50% - 10px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 20px;

                .title {
                    color: white;
                    font-size: 35px;
                    font-weight: 600;
                    padding: 10px 0;
                }

                .description {
                    color: white;
                    font-size: 17px;
                    text-align: justify;
                    line-height: 1.6rem;
                    //  background-color: red;

                    @media (max-width:500px) {

                        font-size: 4vw !important;
                        line-height: 1.4rem;
                        text-align: center;

                    }
                }
            }

            .video-section {
                padding: 20px 10px;
                width: calc(50% - 10px);
                margin-left: 20px;
                border-radius: 4px;
                overflow: hidden;
                // display: flex;
                // flex-direction: column;
                // border: 3px solid blue;

                .videos {
                    width: 100%;

                    // border: 2px solid red;
                    video {
                        width: 100%;
                    }

                    .hidden {
                        display: none;
                    }
                }

                .controls {
                    margin-top: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;

                    @media (max-width:1150px) {
                        margin: 20px 0;
                    }

                    .video-name {
                        padding: 10px;
                        font-weight: 600;
                        font-size: 14px;

                        cursor: pointer;

                        margin: 5px;
                        transition: 0.4s;
                        border-radius: 4px;
                        border: 2px solid $blue;
                        background-color: rgba(0, 0, 0, 0.255);
                        color: $blue;
                        border: 2px solid $blue;
                        text-shadow: 1px 1px 4px black;

                        &:hover {
                            text-decoration: underline;
                            background-color: $blue;
                            color: white;
                        }

                        &.selected {
                            background-color: $blue;
                            color: white;
                            // opacity: 0.8;

                        }

                    }
                }
            }

            img {
                display: block;
                height: 200px;
                ;
            }
        }
    }

    .downloads {
        width: 100vw;
        overflow: hidden;
        // height: 650px;

        display: flex;
        justify-content: center;
        // align-items: center;
        // padding:20px;
        // background-color: $smoke;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            padding: 40px 0;


            .header {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .title {
                    text-align: center;
                    padding: 0 20px;
                    padding-bottom: 10px;
                    font-size: 30px;
                    // text-transform: uppercase;
                    // color:$blue;
                    color: $darkgray;
                    // width:400px;
                    letter-spacing: 1px;
                    border-bottom: 3px solid $red;
                    font-weight: 600;
                }

                .subtitle {
                    margin-top: 40px;
                    color: $darkgray;
                    text-align: center;
                }
            }

            .files {
                flex: 1;
                // margin-top:40px;
                // height: 460px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 20px;

                // border:2px solid red;
                // height: 460px;
                .file {
                    flex: 1;
                    margin: 30px;
                    padding: 10px;
                    background-color: white;
                    border-radius: 4px;
                    width: 320px;
                    height: 450px;
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.316);
                    cursor: pointer;

                    transition: 0.5s;

                    &:hover {
                        transform: scale(1.02);

                        .filename {
                            text-decoration: underline;
                        }
                    }

                    .file-content {
                        // border:1px solid red;
                        height: calc(100% - 90px);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        // overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        .pdf-icon {
                            color: #f40f02;
                            font-size: 180px;
                            // height: calc(100% - );
                        }
                    }

                    .filename {
                        border-radius: 4px;
                        font-weight: 600;
                        padding: 15px;
                        background-color: $darkgray;
                        color: white;
                        margin-bottom: 10px;
                        text-align: center;
                        line-height: 1.5rem;
                        font-size: 15px;
                    }

                    iframe {
                        width: calc(100%);
                        height: calc(100% - 90px);
                        overflow: hidden;
                        // border:none;
                        overflow: hidden;
                        cursor: pointer;
                        overflow: hidden !important;
                        pointer-events: none !important;
                        overflow-x: hidden;
                        overflow-y: hidden;
                    }

                    iframe body {
                        overflow: hidden;
                    }
                }

                @media (max-width:1100px) {
                    // flex-direction: column;

                    .file {
                        // margin:20px 0;
                        // width:100%;
                    }
                }
            }
        }
    }

    .about-us {
        width: 100vw;
        // height: 750px;
        padding: 60px 0;

        display: flex;
        justify-content: center;
        background-color: $smoke;
        overflow: hidden;
        // align-items: center;
        // padding:20px;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: stretch;

            .header {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .title {
                    text-align: center;
                    // width: 200px;
                    padding: 0 20px;
                    // margin:20px 0;
                    padding-bottom: 10px;
                    font-size: 30px;
                    // text-transform: uppercase;
                    // color:$blue;
                    color: $darkgray;
                    // width:400px;
                    letter-spacing: 1px;
                    border-bottom: 3px solid $red;
                    font-weight: 600;
                }

                .subtitle {
                    margin-top: 40px;
                    color: $darkgray;
                    text-align: center;
                    max-width: 800px;
                    color: $gray;
                    line-height: 1.5rem;

                    @media (max-width:900px) {
                        margin-left: 60px;
                        margin-right: 60px;
                    }
                }
            }

            .content {
                margin: 40px 0;
                display: flex;
                justify-content: center;
                // flex-wrap: wrap;
                // align-items: flex-end;
                // border:2px solid red;
                // height: 460px;

                // display: flex;
                .person {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;

                    img {
                        width: 220px;
                        box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.59);
                        border-radius: 5%;
                        cursor: pointer;
                        transition: 0.5s;

                        &:hover {
                            opacity: 0.8;
                            transform: scale(1.02);
                        }
                    }

                    .personal-data {
                        margin: 40px;
                        width: 400px;
                        display: flex;
                        flex-direction: column;

                        @media (max-width:699px) {
                            align-items: center;

                            .description {
                                text-align: center;
                            }
                        }

                        .fullname {
                            font-size: 20px;
                            color: $darkgray;
                            font-weight: 600;
                            color: $blue;
                            margin-bottom: 5px;
                        }

                        .position {
                            text-transform: uppercase;
                        }

                        .description {
                            margin: 20px 0;
                            color: $gray;
                            line-height: 1.5rem;
                            padding: 0 20px;
                        }

                        .social-media {
                            .linkedin-icon {
                                font-size: 30px;
                                color: #0e76a8;

                                transition: 0.5s;

                                &:hover {
                                    transform: scale(1.1);
                                    opacity: 0.9;
                                }
                            }
                        }
                    }
                }
            }
        }

        .wide-logo {
            // border:1px solid red;
            align-self: center;
            width: 450px;

            @media (max-width:600px) {
                width: 80%;
            }
        }
    }

    .contact {
        width: 100vw;
        // height: 750px;
        padding: 40px 0;
        display: flex;
        justify-content: center;
        // align-items: center;
        // padding:20px;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;

            .header {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .title {
                    text-align: center;
                    // width: 200px;
                    padding: 0 20px;
                    // margin:20px 0;
                    padding-bottom: 10px;
                    font-size: 30px;
                    // text-transform: uppercase;
                    // color:$blue;
                    color: $darkgray;
                    // width:400px;
                    letter-spacing: 1px;
                    border-bottom: 3px solid $red;
                    font-weight: 600;
                }

                .subtitle {
                    margin-top: 40px;
                    color: $gray;
                    text-align: center;
                    line-height: 1.5rem;
                    padding: 0 40px;
                }
            }

            .content {
                margin-top: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 20px;
                // border:2px solid red;
                flex-wrap: wrap;


                .map {
                    // height: 100%;
                    // margin:0 10px;
                    height: 460px;
                    width: calc(50% - 10px);
                    // min-width: 400px;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.624);

                    @media (max-width:800px) {
                        width: 90%;
                        margin-bottom: 60px;
                    }

                    iframe {
                        width: 100%;
                        height: 100%;
                        border: none;
                    }
                }

                .contacts {
                    // height: 100%;
                    // margin:0 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: flex-start;
                    width: calc(50% - 10px);

                    // margin-left: 20px;
                    @media (max-width: 800px) {
                        margin-left: 0px;
                        width: 90%;
                        align-items: center;
                    }

                    color:$darkgray;
                    // border:1px solid red;

                    .contact-info {
                        // width:300px;
                        margin: 20px 20px;

                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        @media (max-width:800px) {
                            align-items: center;
                            // margin:20px 0;
                            // text-align: center !important; 

                            .line {
                                text-align: center !important;
                            }
                        }

                        .line {
                            // border:1px solid black;
                            width: 100%;
                            // paddi    ng: 5px 0px;
                            text-align: left;
                            // padding-left: 20px;
                            color: $gray;
                            line-height: 1.2rem;

                            &.bold {
                                padding-bottom: 5px;
                                font-weight: 500;
                            }

                            &.color {
                                margin-bottom: 15px;
                                font-weight: 600;
                                font-size: 25px;
                                color: $blue;
                            }
                        }
                    }
                }
            }
        }
    }
}