@import '../config/variables.scss';

*{
    box-sizing: border-box;
    outline: none;
    // scroll-behavior: smooth;
    margin:0;
    padding: 0;
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-family:Arial, Helvetica, sans-serif;

  &::-webkit-scrollbar {
    width: 9px;
  }

  &::-webkit-scrollbar-track {
    background: #e4e4e4;
  }

  &::-webkit-scrollbar-thumb {
    background: #b1b1b1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(122, 122, 122);
  }

}


html{
  scroll-behavior: smooth;
}

body{
  overflow-x: hidden;
}

.container{
  width:1400px;


  // @media (max-height:600px){
  //   transform: scale(0.9);
  // }

  @media (max-width:1450px){
    width: 100%;
  }
}

footer{
  width:100vw;
  background-color: $darkgray;
  display: flex;
  justify-content: center;
  align-items: center;

  .container{
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:white;

    @media (max-width:600px){
      flex-direction: column;
      // align-items: center;

      .navigator{
        margin-bottom: 40px;
      }

      .company-name{
        margin:0px !important;
      }
    }

    .navigator{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .link{
        padding: 10px;
        cursor: pointer;
        text-transform: uppercase;
        font-size:12px;

        &:hover{
          color: rgb(182, 182, 182);
        }
      }
    }

    .company-name{
      margin-left: 80px;
      color:rgb(186, 186, 186);
      font-size:14px;
    }
  }
}


select, input, button{
  // padding: 0;
}

.text{
  text-shadow: 2px 2px 3px #00000055;
}

.float-left{
  float: left;
}

.inline{
  display: inline;
}

@keyframes fadeIn{
  0%{
      opacity: 0;
  }
  100%{
      opacity: 1;
  }
}

.slide-down{
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1.05, 0, 1);;
}

.slide-up{
  max-height: 6000px;
  transition: max-height 0.5s cubic-bezier(.82,.15,.94,-0.15)
}

.inline-error{
  padding:10px;
  color:$red;
  background-color: rgb(253, 175, 175);
  border:2px solid $red;
  font-weight:500;
  width: 100%;
  text-align: center;
  font-size: 16px;
  border-radius: 3px;
}

.error{
  color:$red !important;
  background-color: rgb(253, 175, 175) !important;
  border-color: $red !important;
;
}

.hide{
  visibility: hidden;
}

.field-error{
  position: absolute;
  color:$red;
  font-weight: 600;
  width:100%;
  text-align: right;
  font-size: 13px;
}

.section{
  width: 100vw;
  // height: 100vh;
  // overflow:hidden;
}
.wrap{
  position: relative;
}

.wrap:after {
  content: "";
  display: block;
  position: absolute;
  left:0;
  right:0;
  bottom:-1px;
  height: 40px;
  background-color: white;
  -webkit-clip-path: polygon(0 0, calc(50% - 30px) 0,50% 50%, calc(50% + 30px) 0, 100% 0, 100% 100%, 0 100%);
  -moz-clip-path: polygon(0 0, calc(50% - 30px) 0,50% 50%, calc(50% + 30px) 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, calc(50% - 30px) 0,50% 50%, calc(50% + 30px) 0, 100% 0, 100% 100%, 0 100%);
}

.afterwrap{
  margin-bottom: 40px;
}

a{
  text-decoration: none;
  color:inherit;
}

.scroll::-webkit-scrollbar {
  width: 9px !important;
}

.scroll::-webkit-scrollbar-track {
  background: #e4e4e4 !important;
}

.scroll::-webkit-scrollbar-thumb {
  background: #b1b1b1 !important;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: rgb(122, 122, 122) !important;
}