@import '../../config/variables.scss';

.Page404{
    background-color: white;
    height: calc(100vh - 75px);
    display: flex;
    justify-content: center;
    .box{
        width:500px;
        border-radius: 3px;
        padding: 40px;
        border:1px solid $red;
        margin-top: 260px;
        height: 160px;
        box-shadow: 2px 2px 4px rgba(218, 18, 18, 0.507);
        font-weight: 600;
        
        .title{
            display: inline;
            font-size:20px;
            color:$red;
            margin-bottom: 20px;
        }
    }
}